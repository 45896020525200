import {
 
  education,
  insa,
  sharon,

} from "../assets";



const experiences = [
  {
    title: "Arba Minch University",
    company_name: "Computer Science",
    icon: education,
    iconBg: "#383E56",
    date: "January 2012 - June 2016",
    points: [
     
    ],
  },
  {
    title: "Software Developer",
    company_name: "Information and Network Security Agency ",
    icon: insa,
    iconBg: "#E6DEDD",
    date: "January 2016 - August 2021",
    points: [
      	"Created an enterprise-level billing system utilizing Spring Boot and React, resulting in a 30% decrease in billing errors and a 25% increase in overall billing accuracy. Successfully deployed the application on AWS cloud services",
        "Implemented a resilient microservices architecture with 50+ services, enhancing scalability and adaptability for the billing system",
        "Implemented RESTful services to provide robust and scalable APIs, resulting in a significant improvement in API response times, contributing to a better user experience for our clients"
    ],
  },
  {
    title: "Full Stack Java Developer",
    company_name: "Sharonat International",
    icon: sharon,
    iconBg: "#383E56",
    date: "November 2021 - September 2022",
    points: [
      "Developed a comprehensive event management system as part of a collaborative team effort, utilizing various technologies across the entire SDLC",
      "Proficiently utilized Java, Spring Boot, JavaScript, React, and PostgreSQL to architect efficient and maintainable code, implementing both front-end and back-end functionalities",
      "Implemented AWS services to optimize application scalability, utilizing Amazon EC2 for hosting and Amazon RDS for database management"
    ],
  },
  {
    title: "Maharishi International University",
    company_name: "Master of Computer Science",
    icon: education,
    iconBg: "#E6DEDD",
    date: "October 2022 - Present",
    points: [
      
    ],
  },
];




export { experiences };
